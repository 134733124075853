@import "assets/scss/abstract/abstract";

.banner {
  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    max-width: var(--yps-max-width);
    margin: auto;
    align-items: center;

    > a {
      display: block;
      width: 100%;
      height: 100%;
    }

    &__right,
    &__left {
      @include not-wide {
        display: none !important;
      }
    }

    &__right {
      width: calc((100vw - var(--yps-max-width)) / 2);
      position: absolute;
      right: 0;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
    }

    &__left {
      width: calc((100vw - var(--yps-max-width)) / 2);
      position: absolute;
      left: 0;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__single {
    text-align: center;
  }

  &__image {
    max-width: calc(100% - 30px);
  }
}